/* Checkbox */
.form-checkbox .check-btn:after {
  width: 1.25rem;
  height: 1.25rem;
  transform: rotate(-20deg);
  content: '';
  position: absolute;
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  top: 0;
  left: 0;
  background: no-repeat
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -7 24 24' width='24' height='24'%3E%3Cpath fill='%23fff' d='M5.486 9.73a.997.997 0 0 1-.707-.292L.537 5.195A1 1 0 1 1 1.95 3.78l3.535 3.535L11.85.952a1 1 0 0 1 1.415 1.414L6.193 9.438a.997.997 0 0 1-.707.292z'%3E%3C/path%3E%3C/svg%3E")
    center center / contain;
}
.form-checkbox input:checked ~ .check-btn {
  background-color: #1494d6;
}
.form-checkbox input:checked ~ .check-btn:after {
  opacity: 1;
  transform: rotate(0);
}

/* Radio */
.form-radio .radio-btn:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  content: '';
  position: absolute;
  opacity: 0;
  transition: all 0.2s;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.form-radio input:checked ~ .radio-btn {
  background-color: #1494d6;
}
.form-radio input:checked ~ .radio-btn:after {
  opacity: 1;
}
