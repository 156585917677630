.animate {
  will-change: opacity, transform;
  @apply opacity-0;
}

.animate-0 {
  animation: fadeInUp-sm 0.3s forwards;
}
.animate-1 {
  animation: fadeInUp-sm 0.3s forwards;
}
.animate-2 {
  animation: fadeInUp-sm 0.3s forwards 0.45s;
}
.animate-3 {
  animation: fadeInUp-sm 0.3s forwards 0.6s;
}
.animate-4 {
  animation: fadeInUp-sm 0.3s forwards 0.75s;
}
.animate-5 {
  animation: fadeInUp-sm 0.3s forwards 0.9s;
}
.animate-6 {
  animation: fadeInUp-sm 0.3s forwards 1.05s;
}

@keyframes fadeInUp-sm {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, -20px);
  }

  to {
    transform: perspective(1000px) translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInUp-md {
  from {
    opacity: 0;
    transform: translate3d(0, 45px, -10px);
  }

  to {
    transform: perspective(1000px) translate3d(0, 0, 0);
    opacity: 1;
  }
}

.anim {
  @apply opacity-0;
  will-change: opacity, transform;
}

.anim0 {
  animation: elementsAnimation 0.15s forwards;
}

.anim0-25 {
  animation: elementsAnimation 0.15s forwards 0.025s;
}

.anim0-5 {
  animation: elementsAnimation 0.15s forwards 0.05s;
}

.anim0-75 {
  animation: elementsAnimation 0.15s forwards 0.075s;
}

.anim1 {
  animation: elementsAnimation 0.15s forwards 0.1s;
}

.anim1-25 {
  animation: elementsAnimation 0.15s forwards 0.125s;
}

.anim1-5 {
  animation: elementsAnimation 0.15s forwards 0.15s;
}

.anim1-75 {
  animation: elementsAnimation 0.15s forwards 0.175s;
}

.anim2 {
  animation: elementsAnimation 0.2s forwards 0.2s;
}

.anim2-5 {
  animation: elementsAnimation 0.2s forwards 0.2s;
}

.anim2-25 {
  animation: elementsAnimation 0.2s forwards 0.225s;
}

.anim2-5 {
  animation: elementsAnimation 0.2s forwards 0.25s;
}

.anim2-75 {
  animation: elementsAnimation 0.2s forwards 0.275s;
}

.anim3 {
  animation: elementsAnimation 0.3s forwards 0.3s;
}

.anim3-25 {
  animation: elementsAnimation 0.3s forwards 0.325s;
}

.anim3-5 {
  animation: elementsAnimation 0.3s forwards 0.35s;
}
.anim3-75 {
  animation: elementsAnimation 0.3s forwards 0.35s;
}

.anim4 {
  animation: elementsAnimation 0.4s forwards 0.4s;
}

@keyframes elementsAnimation {
  from {
    opacity: 0;
    transform: perspective(400px) translate3d(0px, 30px, -40px);
  }

  to {
    transform: perspective(1000px) translate3d(0, 0, 0);
    opacity: 1;
  }
}
