/* Tailwind Base */
@import 'tailwindcss/base';

/* Tailwind Components */
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  .text-shadow {
    text-shadow: 0px 0px 3px rgb(0 0 0 / 24%);
  }
}
