.task-single.card_base,
.task-single.ocean_blue {
  background: radial-gradient(circle, #1f94c7 0%, #0071bb 100%);
}

.task-single.dark_blue {
  background: radial-gradient(circle, #5e7ca8 0%, #4a6388 100%) !important;
}

.task-single.midnight_purple {
  background: radial-gradient(circle, #513094 0%, #351d66 100%);
}

.task-single.winter_sky_blue {
  background: radial-gradient(circle, #1261d7 0%, #0e4ead 100%);
}

.task-single.forest_green {
  background: radial-gradient(circle, #73c746 0%, #21b573 100%);
}

.task-single.electric_blue {
  background: rgb(11, 185, 201);
  background: radial-gradient(
    circle,
    rgba(11, 185, 201, 1) 0%,
    rgba(0, 154, 169, 1) 100%
  );
}

.task-single.essential_grey {
  background: rgb(94, 124, 168);
  background: radial-gradient(
    circle,
    rgba(94, 124, 168, 1) 0%,
    rgba(74, 99, 136, 1) 100%
  );
}

.task-single.sunset_orange {
  background: rgb(249, 166, 48);
  background: radial-gradient(
    circle,
    rgba(249, 166, 48, 1) 0%,
    rgba(224, 138, 16, 1) 100%
  );
}

.task-single.zen_green {
  background: rgb(27, 209, 128);
  background: radial-gradient(
    circle,
    rgba(27, 209, 128, 1) 0%,
    rgba(33, 181, 115, 1) 100%
  );
}

.task-single.summer_red {
  background: rgb(244, 70, 104);
  background: radial-gradient(
    circle,
    rgba(244, 70, 104, 1) 0%,
    rgba(205, 33, 67, 1) 100%
  );
}

.task-single.gold_yellow {
  background: radial-gradient(
    circle,
    rgba(236, 200, 77, 1) 0%,
    rgba(219, 185, 66, 1) 100%
  );
}

.task-single.EmploymentProfiler2105 {
  background: no-repeat url('../img/b2b_profiler_card_bg.svg') center bottom /
    cover;
}

.task-single.GeneralProfiler2105 {
  background: no-repeat url('../img/general_profiler_card_bg.svg') center bottom /
    cover;
}

.task-single.SportProfiler2105 {
  background: no-repeat url('../img/sports_profiler_card_bg.svg') center bottom /
    cover;
}
.task-single.LifestyleProfiler2105 {
  background: no-repeat url('../img/kids_profiler_card_bg.svg') center bottom /
    cover;
}
.task-single.GamerProfiler2105 {
  background: no-repeat url('../img/gamer_profiler_card_bg.svg') center bottom /
    cover;
}
.task-single.AutoProfiler2105 {
  background: no-repeat url('../img/automotive_profiler_card_bg.svg') center
    bottom / cover;
}
.task-single.DietProfiler2105 {
  background: no-repeat url('../img/diet_profiler_card_bg.svg') center bottom /
    cover;
}
.task-single.HealthcareProfiler2105 {
  background: no-repeat url('../img/health_profiler_card_bg.svg') center bottom /
    cover;
}
.task-single.HouseholdProfiler2105 {
  background: no-repeat url('../img/homeowner_profiler_card_bg.svg') center
    bottom / cover;
}
.task-single.FinancialProfiler2105 {
  background: no-repeat url('../img/financial_profiler_card_bg.svg') center
    bottom / cover;
}

.task-single.GoalVerifyEmail {
  background: no-repeat url('../img/verify_email_quest_card_bg.svg') bottom
    right / cover;
}

.task-single.GoalVerifyPhone {
  background: no-repeat url('../img/verify_phone_quest_card_bg.svg') bottom
    right / cover;
}

.task-single.GoalReferral {
  background: no-repeat url('../img/invite_friend_profiler_card_bg.svg') bottom
    right / cover;
}

.task-single .content p {
  @apply mb-4;
}

.task-lock .overlay {
  @apply opacity-50;
}

.task-lock:hover .overlay {
  @apply opacity-25;
}

.task-lock:hover .lock {
  transform-origin: center center;
  animation: swing 0.5s ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
