@import './page.css';
@import './task.css';
@import './animations.css';
@import './form.css';
@import './vendors/glide.css';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  @apply font-sans text-grey-900 min-h-screen overflow-x-hidden;
}

*:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply m-0 p-0 font-montserrat text-grey-900;
}

h1 {
  @apply text-3xl md:text-4xl mb-4;
}

h2 {
  @apply text-2xl mb-4;
}

.ul-list {
  @apply list-disc pl-6;
}

.ul-list li {
  @apply mb-2;
}

p {
  @apply text-grey-500;
}

/* Button */

.primary {
  background: linear-gradient(90deg, #1394d6, #1baaf2, #1394d6);
  background-size: 200% 200%;
  /* transition: background-position 0.22s ease-in-out; */
}

.primary:hover {
  background-position: 100% 150%;
}

.success {
  background: linear-gradient(90deg, #22b573, #3bd992, #22b573);
  background-size: 200% 200%;
}

.success:hover {
  background-position: 100% 150%;
}

/* Or Divider */

.or-divider:after {
  content: 'Or';
  @apply bg-white -top-3 px-4 relative;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Blog */
.blog-link:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 38%);
  transition: opacity 0.2s;
}
.blog-link:hover:after {
  opacity: 0.4;
}

#breakpoint-check {
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  #breakpoint-check {
    opacity: 0.2;
  }
}
